#addGirl {
    width: 20px
}

.girl-form {
    display: flex;
    flex-direction: column;
    width: fit-content;
    gap: 5px;
    padding: 5px;
}
